import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const PrayerHymns = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
    <div className="row bread_cumb">
    <h2> Prayer & Hymns</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i>  Prayer & Hymns</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont pray">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <div className="verticaltabs">
   <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
     <button className="nav-link active" id="v-pills-schoolprayer-tab" data-bs-toggle="pill" data-bs-target="#v-pills-schoolprayer" type="button" role="tab" aria-controls="v-pills-schoolprayer" aria-selected="true">SCHOOL PRAYER</button>
     <button className="nav-link" id="v-pills-prayermorning-tab" data-bs-toggle="pill" data-bs-target="#v-pills-prayermorning" type="button" role="tab" aria-controls="v-pills-prayermorning" aria-selected="false">PRAYERS FOR MORNING ASSEMBLY</button>
     <button className="nav-link" id="v-pills-prayerbefore-tab" data-bs-toggle="pill" data-bs-target="#v-pills-prayerbefore" type="button" role="tab" aria-controls="v-pills-prayerbefore" aria-selected="false">PRAYER BEFORE CLASS</button>
     <button className="nav-link" id="v-pills-prayerafterclass-tab" data-bs-toggle="pill" data-bs-target="#v-pills-prayerafterclass" type="button" role="tab" aria-controls="v-pills-prayerafterclass" aria-selected="false">PRAYER AFTER CLASS</button>
     <button className="nav-link" id="v-pills-gracemeals-tab" data-bs-toggle="pill" data-bs-target="#v-pills-gracemeals" type="button" role="tab" aria-controls="v-pills-gracemeals" aria-selected="false">GRACE BEFORE MEALS</button>
     <button className="nav-link" id="v-pills-graceafter-tab" data-bs-toggle="pill" data-bs-target="#v-pills-graceafter" type="button" role="tab" aria-controls="v-pills-graceafter" aria-selected="false">GRACE AFTER MEALS</button>
     <button className="nav-link" id="v-pills-prayerend-tab" data-bs-toggle="pill" data-bs-target="#v-pills-prayerend" type="button" role="tab" aria-controls="v-pills-prayerend" aria-selected="false">PRAYER AT THE END OF THE DAY</button>
     <button className="nav-link" id="v-pills-pledge-tab" data-bs-toggle="pill" data-bs-target="#v-pills-pledge" type="button" role="tab" aria-controls="v-pills-pledge" aria-selected="false">THE PLEDGE</button>
     <button className="nav-link" id="v-pills-national-tab" data-bs-toggle="pill" data-bs-target="#v-pills-national" type="button" role="tab" aria-controls="v-pills-national" aria-selected="false">OUR NATIONAL ANTHEM</button>
     <button className="nav-link" id="v-pills-schoolanthem-tab" data-bs-toggle="pill" data-bs-target="#v-pills-schoolanthem" type="button" role="tab" aria-controls="v-pills-schoolanthem" aria-selected="false">SCHOOL ANTHEM</button>
   </div>
   <div className="tab-content" id="v-pills-tabContent">
     <div className="tab-pane fade show active" id="v-pills-schoolprayer" role="tabpanel" aria-labelledby="v-pills-schoolprayer-tab"><h3>SCHOOL PRAYER</h3>
         <p > O LORD! HELP ME TO BE A TRUE HUMAN BEING. <br/>
                 ALL THE DAY, HELP ME TO DO MY LESSONS WELL, AND TO BE TRUE AND HAPPY.<br/>
                  OUR FATHER IN HEAVEN, HOLY BE YOUR NAME, YOUR KINGDOM COME, <br/>
                  YOUR WILL BE DONE ON EARTH, AS IT IS IN HEAVEN GIVE US THIS DAY, <br/>
                  OUR DAILY BREAD, AND FORGIVE US OUR SINS, AS WE FORGIVE, THOSE WHO SIN AGAINST US.<br/>
                   DO NOT BRING US TO THE TEST, BUT DELIVER US FROM EVIL. ...AMEN </p>
 
     </div>
     <div className="tab-pane fade" id="v-pills-prayermorning" role="tabpanel" aria-labelledby="v-pills-prayermorning-tab"><h3>PRAYERS FOR MORNING ASSEMBLY</h3>
   <p>FATHER, THIS DAY IS YOUR GIFT TO US, FILLED WITH ALL THE<br/>
    BLESSINGS OF LIFE, THE GOOD THINGS OF EARTH WHICH COME FROM YOUR<br/>
    HANDS. WE STAND IN WONDER AT ALL THAT YOU HAVE GIVEN US AND<br/>
    ASK WHAT WE CAN GIVE YOU IN RETURN. ALL YOU ASK OF THOSE YOU<br/>
    HAVE CALLED YOUR CHILDREN IS THAT OUR LOVE FOR EACH OTHER BE REAL.<br/>
    MAY WE NOW SET ASIDE ALL THAT IS EMPTY AND SHALLOW, ALL THAT<br/>
    KEEPS US AT A DISTANCE FROM EACH OTHER AND FROM YOU. HELP US TO<br/>
    BREAK THROUGH TO THAT AREA OF HUMAN LIFE WHERE MEN CAN TOUCH<br/>
    EACH OTHER IN LOVE, FOR THERE WE WILL FIND WHAT YOU SAID WHEN YOU<br/>
    MADE US TO BE LIKE YOU. ALL GLORY BE TO YOU. FATHER, NOW AND FOREVER.<br/>
    
    AMEN  </p>
 
     </div>
     <div className="tab-pane fade" id="v-pills-prayerbefore" role="tabpanel" aria-labelledby="v-pills-prayerbefore-tab"><h3>PRAYER BEFORE CLASS</h3>
  <p> GOD, WE DEDICATE THIS DAY TO YOU,<br/>
    THE THOUGHTS WE THINK,<br/>
    THE WORDS WE SPEAK,<br/>
    THE WORK WE DO,<br/>
    ACCEPT IT ALL AS<br/>
    OUR HUMBLE OFFERING.<br/>
    ...AMEN </p>
 
     </div>
     <div className="tab-pane fade" id="v-pills-prayerafterclass" role="tabpanel" aria-labelledby="v-pills-prayerafterclass-tab"><h3>PRAYER AFTER CLASS</h3>
   <p> WE GIVE YOU THANKS, ALMIGHTY GOD, OUR FATHER,<br/>
    FOR ALL THE BENEFITS, YOU BESTOWED ON US.<br/>
    WE FEEL SORRY, FOR OFFENDING OUR BRETHREN,<br/>
    AND PROMISE YOU, TO AVOID IT IN FUTURE.<br/>
    BLESS OUR SCHOOL, AND THOSE WHO RUN IT.<br/>
    GIVE PEACE AND PROSPERITY TO ALL THE NATIONS.<br/>
    ...AMEN </p>
 
     </div>
      <div className="tab-pane fade" id="v-pills-gracemeals" role="tabpanel" aria-labelledby="v-pills-gracemeals-tab"><h3>GRACE BEFORE MEALS</h3>
     <p>BLESS US, O LORD, AND THESE YOUR GIFTS,<br/>
    WHICH WE ARE ABOUT TO RECEIVE FROM YOUR BOUNTY<br/>
    ...AMEN  </p>
 
      </div>
       <div className="tab-pane fade" id="v-pills-graceafter" role="tabpanel" aria-labelledby="v-pills-graceafter-tab"><h3>GRACE AFTER MEALS</h3>
 
   <p> WE GIVE YOU THANKS, ALMIGHTY GOD,<br/>
    FOR ALL THE BENEFITS WE HAVE RECEIVED FROM YOUR BOUNTY<br/>
    ...AMEN </p>
       </div>
        <div className="tab-pane fade" id="v-pills-prayerend" role="tabpanel" aria-labelledby="v-pills-prayerend-tab"><h3>PRAYER AT THE END OF THE DAY</h3>
 
     <p> THANK YOU, LOVING FATHER, FOR WHAT I HAVE LEARNT, DURING THIS DAY. FOR THE<br/>
    KINDNESS AND CARE, THAT I HAVE EXPERIENCED FROM MY ELDERS AND<br/>
    FRIENDS. HELP ME THROUGH-OUT MY LIFE, IN SHARING THIS LOVE WITH<br/>
    OTHERS, DOING GOOD AND SHUNNING EVIL, AND AT THE SAME TIME,<br/>
    BEING HONEST WITH MY DUTIES AS A CHILD.<br/>
    ...AMEN </p>
        </div>
         <div className="tab-pane fade" id="v-pills-pledge" role="tabpanel" aria-labelledby="v-pills-pledge-tab"><h3>THE PLEDGE</h3>
 
      <p> I AM PROUD TO BE AN INDIAN. I BELIEVE THAT, <br/> WE ARE ALL GOD'S CHILDREN AND, THEREFORE, BROTHERS AND SISTERS TO ONE ANOTHER. <br/>I KNOW THAT SOME OF MY FELLOW COUNTRYMEN PROFESS DIFFERENT FAITHS, <br/>
                     BUT THEY ARE ALL A PART OF MY OWN HUMAN FAMILY.<br/>
    I PLEDGE TO LOVE ALL MY COUNTRYMEN REGARDLESS OF THEIR RELIGION OR REGION AND TO DO ALL I CAN TO MAKE MY COUNTRY A GREAT NATION.<br/>
    I ALSO PLEDGE TO RESPECT MY COUNTRY'S ENVIRONMENT,<br/>SO THAT THE NEXT GENERATION CAN LIVE IN SECURITY AND PEACE.
    SO HELP ME GOD
    ...AMEN </p>
         </div>
          <div className="tab-pane fade" id="v-pills-national" role="tabpanel" aria-labelledby="v-pills-national-tab"><h3>OUR NATIONAL ANTHEM</h3>
    <p>JANA-GANA-MANA ADHINAYAKA JAYA HE,<br/>
    BHARATA BHAGYA VIDHATA,<br/>
    PUNJAB SINDHU GUJARATA MARATHA<br/>
    DRAVIDA UTKALA BANGA,<br/>
    VINDHYA-HIMACHAL, YAMUNA-GANGA<br/>
    UCHCHALA-JALADHI-TARANGA<br/>
    TAVA SHUBHA NAME JAGE,<br/>
    TAVA SHUBH ASHISH MANGE,<br/>
    GAHE TAV JAY-GATHA<br/>
    JANA-GANA-MANGAL DAYAK JAYA HE,<br/>
    BHARATA-BHAGYA VIDHATA,<br/>
    JAYA HE, JAYA HE, JAYA HE,<br/>
    JAYA, JAYA, JAYA, JAYA, HE!<br/>
    -RABINDRANATH TAGORE  </p>
 
          </div>
           <div className="tab-pane fade" id="v-pills-schoolanthem" role="tabpanel" aria-labelledby="v-pills-schoolanthem-tab"><h3>SCHOOL ANTHEM</h3>
     <p> FIX YOUR EYES; XAVIERITES, ON THE STAR!<br/>
    STAR DIVINE, BECKONING, ONE AND ALL,<br/>
    ONE AND ALL, YOU AND ME, CLOSE AND FAR,<br/>
    CLOSE AND FAR-HEARKEN TO ITS CLARION CALL;<br/>
    "GOD IS LOVE, BUSY LOVING YOU AND ME, DEAR"<br/>
    HEAR THIS CALL, WHICH IS COMING FROM ABOVE<br/>
    TAKE THIS GOOD NEWS AND BANISH EVERY SAD TEAR,<br/>
    SPREAD THIS MESSAGE OF HEARTY CHEER AND LOVE. </p>
 
           </div>
   </div>
 </div> 
   <div className="clr"></div>
   </div>

<AboutSidebar/>
</div>
</div>
  <Footer/>
  </>
  )
}

export default PrayerHymns
