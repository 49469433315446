import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { getGalleryInner } from '../Service/Api'; // Adjust the import according to your API service location

const PhotoGallery = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedMonth, setSelectedMonth] = useState("0");
  const [searchQuery, setSearchQuery] = useState("");
  const [year, setYear] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getGalleryInner();
      setData(galleryData);
      const uniqueYears = Array.from(
        new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
      );
      uniqueYears.sort((a, b) => b - a);
      setYear(uniqueYears);
      setFilteredData(galleryData); // Initialize filteredData with all data
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Filter data based on selected year, month, and search query
    let filteredData = data;

    if (selectedYear && selectedYear !== "All") {
      filteredData = filteredData.filter((item) => {
        const itemYear = new Date(item.date).getFullYear();
        return itemYear === parseInt(selectedYear);
      });
    }

    if (selectedMonth && selectedMonth !== "0") {
      filteredData = filteredData.filter((item) => {
        const itemMonth = new Date(item.date).getMonth() + 1;
        return itemMonth === parseInt(selectedMonth);
      });
    }

    if (searchQuery) {
      filteredData = filteredData.filter((item) => 
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredData(filteredData);
  }, [selectedYear, selectedMonth, searchQuery, data]);

  const monthsOfYear = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const handleMonth = (month) => {
    return monthsOfYear[month - 1];
  };

  const emptyArray = [
    { title: "No Data Found", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
    { title: "No Data Found", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
    { title: "No Data Found", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
    { title: "No Data Found", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
  ];

  const handleModal = (id) => {
    navigate(`/SubGallery?id=${id}`); // Pass the ID as a query parameter in the URL
  }

  return (
    <>
      <Header />
      <div className="inner_slider">
        <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda" /></LazyLoad>
        <div className="row bread_cumb">
          <h2>Photo Gallery</h2>
          <div className="theme-heading">
            <span className="colorW marB10"><i className="bi bi-building"></i></span>
          </div>
          <h5>
            <Link to="/">Home <i className="bi bi-arrow-right"></i> </Link>
            <span> Bulletin <i className="bi bi-arrow-right"></i>  Gallery <i className="bi bi-arrow-right"></i> Photo Gallery</span>
          </h5>
        </div>
      </div>
      <div className="innerpage">
        <div className="inner_cont">
          <div className="row tabs-dynamic">
            <div className="col-md-3">
              <div className="count-val">
                <p>Total Count: {filterData.length}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="month-selection">
                <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                  <option value="All">All Years</option>
                  {year.map((yr, index) => (
                    <option key={index} value={yr}>{yr}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="month-selection">
                <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                  <option value="0">All Months</option>
                  {monthsOfYear.map((month, index) => (
                    <option key={index} value={index + 1}>{month}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="searchBox">
                <input 
                  type="text" 
                  id="myInput" 
                  name="name" 
                  autoComplete="off" 
                  placeholder="Search Here.." 
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {filterData.length > 0 ? (filterData?.filter((item) =>  item.school === "Senior"  && item.category === "PhotoGallery").map((item, index) => (
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount" onClick={() => handleModal(item?._id)}>
                  <div className="gallery-inner">
                    <div className="gallery-blk">
                    <LazyLoad>
                    <img src={item.attachments.length ? `https://webapi.entab.info/api/image/${item.attachments[0]}` : 'placeholder-image-url'} className="img-fluid" alt="St. Xaviers Bathinda" />
                    </LazyLoad>
                    </div>
                    <div className="gallery-content">
                      <div className="gallery-date">
                        <h6>{new Date(item.date).getDate()} {handleMonth(new Date(item.date).getMonth() + 1)}</h6>
                        <div className="gall-num">
                          <h5><span>Number Of Photos :</span> {item.numberOfPhotos}</h5>
                        </div>
                      </div>
                      <div className="gallery-title">
                        <h3>{item.title}</h3>
                      </div>
                      <Link onClick={() => handleModal(item?._id)} className="view-more">View More <i className="bi bi-arrow-right"></i></Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              emptyArray.map((data, index) => (
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount" data-month="1">
                  <div className="gallery-inner">
                    <div className="gallery-blks">
                      <div className="backDiv"></div>
                      <LazyLoad><img src={data.attachments || 'placeholder-image-url'} className="img-fluid" alt="St. Mary's School" /></LazyLoad>
                    </div>
                    <div className="gallery-content">
                      <div className="gallery-date">
                        <h6>{data.day} <span>{data.month}</span></h6>
                        <div className="gall-num">
                          <h5><span>Number Of Photos :</span>00</h5>
                        </div>
                      </div>
                      <div className="gallery-title">
                        <h3>{data.title}</h3>
                      </div>
                      <Link to="#" className="view-more">View More <i className="bi bi-arrow-right"></i></Link>
                    </div>
                  </div>
                </div>
              ))
            )}
            {/* <div className="load-more">
              <div className="next">Show More</div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PhotoGallery;
