import React, { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload';
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link, useLocation, useNavigate } from 'react-router-dom' 
import { getSubGallery } from '../Service/Api';
const SubGallery = () => {
    const navigate = useNavigate();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [data, setData] = useState("");
  const [index, setIndex] = React.useState(-1);

  console.log(id);

  useEffect(() => {
    const getData = async () => {
      try {
        let subgalleryData = await getSubGallery(id);
        console.log(subgalleryData);
        setData(subgalleryData);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  console.log(data);

  const slides = data?.attachments?.map(img => ({
    src: `https://webapi.entab.info/api/image/${img}`
  }));

  return (
    <>
<Header/>
     <div className="inner_slider">
   <LazyLoad>  <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
     <div className="row bread_cumb">
     <h2>Photo Categories</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Bulletin <i className="bi bi-arrow-right"></i>  Gallery <i className="bi bi-arrow-right"></i> Photo Categories</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
        <div className="inner_cont">
        <div className="row tabs-dynamic">
            <div className="col-md-6 col-xl-3">
              <div className="count-val">
                {/* Use navigate function to programmatically navigate */}
                <p>
                  <a href='' onClick={() => navigate("/PhotoGallery")}>
                    <span id="lblcount">
                      <i className="bi bi-arrow-left"></i>
                    </span>{" "}
                    Go Back
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="month-selection">
                <p>
                  <span>Event Date:</span>{" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="month-selection">
                <p>
                  <span>Last Update:</span>{" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="searchBox">
                <p>
                  <span>No. Of Photos:</span> {data?.attachments?.length}
                </p>
              </div>
            </div>
          </div>
      <div className="row">
        <div className="col-md-12">
            <h4 className="gallery-title">{data.title}</h4>
            <p>{data.description} </p>
        </div>

        {data !== "" &&
              data?.attachments.map((img,index) => (
       <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2 galleryCount sub-gallerycount">
            <div className="gallery-blk">
            <LazyLoad> <a data-magnify="gallery" data-src="" data-group="a" href={`https://webapi.entab.info/api/image/${img}`}><img src={`https://webapi.entab.info/api/image/${img}`} class="img-fluid" alt="" /></a> </LazyLoad>
                
                
            </div>
        </div>
 ))}

       {/* <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
            <div className="gallery-blk">
                
            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/Images/gal_4.jpg"><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/gal_4.jpg" class="img-fluid" alt="" /></a> 
                
            </div>
        </div> */}

  
    </div> 

        </div>
    </div>
   <Footer/>
  </>
  )
}

export default SubGallery
