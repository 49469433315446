import React, { useEffect, useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link, useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { getGalleryInner } from '../Service/Api';
const Achievements = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("0");
    const [searchQuery, setSearchQuery] = useState("");
    const [year, setYear] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 to expand the first item
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const galleryData1 = await getGalleryInner();
            const galleryData = galleryData1.filter(item => item.school === "Senior"  && item.category === 'Achievements' );

            setData(galleryData); 
            const uniqueYears = Array.from(
                new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
            );
            uniqueYears.sort((a, b) => b - a);
            setYear(uniqueYears);
            setFilteredData(galleryData); // Initialize filteredData with all data
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Filter data based on selected year, month, and search query
        let filteredData = data;

        if (selectedYear && selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                const itemYear = new Date(item.date).getFullYear();
                return itemYear === parseInt(selectedYear);
            });
        }

        if (selectedMonth && selectedMonth !== "0") {
            filteredData = filteredData.filter((item) => {
                const itemMonth = new Date(item.date).getMonth() + 1;
                return itemMonth === parseInt(selectedMonth);
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    const monthsOfYear = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const handleMonth = (month) => {
        return monthsOfYear[month - 1];
    };

    const handleAccordionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const emptyArray = [
        { title: "No Data Found", day: "DD", month: "MM", attachments: ["https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg"] }, 
    ];

    const handleModal = (id) => {
        navigate(`/SubGallery?id=${id}`); // Pass the ID as a query parameter in the URL
    };

    return (
        <>
            <Header />
            <div className="inner_slider">
                <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda" /></LazyLoad>
                <div className="row bread_cumb">
                    <h2>Achievements</h2>
                    <div className="theme-heading">
                        <span className="colorW marB10"><i className="bi bi-building"></i></span>
                    </div>
                    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>Achievements</span></h5>
                </div>
            </div>
            <div className="innerpage inner_cont">
                <div className="row tabs-dynamic">
                    <div className="col-md-3">
                        <div className="count-val">
                            <p>Total Count: {filterData.length}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="month-selection">
                            <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                <option value="All">All Years</option>
                                {year.map((yr, index) => (
                                    <option key={index} value={yr}>{yr}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="month-selection">
                            <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                <option value="0">All Months</option>
                                {monthsOfYear.map((month, index) => (
                                    <option key={index} value={index + 1}>{month}</option>
                                ))}
                            </select>
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="searchBox">
                            <input
                                type="text"
                                id="myInput"
                                name="name"
                                autoComplete="off"
                                placeholder="Search Here.."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="accordion achievementacc" id="accordionExample">
                        {filterData.length > 0 ? (
                            filterData.map((item, index) =>
                             {
                                const date = new Date(item.date);
                                const day = date.getDate();
                                const month = handleMonth(date.getMonth() + 1);
                                const year = date.getFullYear();

                                return (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={`heading${index}`}>
                                            <button
                                                className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${index}`}
                                                aria-expanded={activeIndex === index ? 'true' : 'false'}
                                                aria-controls={`collapse${index}`}
                                                onClick={() => handleAccordionClick(index)}
                                            >
                                                <p>{item.title}</p>
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapse${index}`}
                                            className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                                            aria-labelledby={`heading${index}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <div className="accordin-img">
                                                <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href={`https://webapi.entab.info/api/image/${item.attachments[0]}`}>
                                                        <img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} className="img-fluid" alt="" />
                                                    </a>
                                                </LazyLoad>
                                                </div>
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                                <div className="achievementdesc">
                                                    <p><i className="bi bi-calendar"></i> {day}.{month}.{year}</p>
                                                     
                                                </div>
                                                <div className="clr"></div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }) ) : (
                                emptyArray.map((item, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={`headingEmpty${index}`}>
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapseEmpty${index}`}
                                                aria-expanded="false"
                                                aria-controls={`collapseEmpty${index}`}
                                            >
                                                <p>{item.title}</p>
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapseEmpty${index}`}
                                            className="accordion-collapse collapse"
                                            aria-labelledby={`headingEmpty${index}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <div className="accordin-img">
                                                <LazyLoad> <a data-magnify="gallery" data-src="" data-group="a" href={item.attachments[0]}>
                                                        <img src={item.attachments[0]} className="img-fluid" alt="" />
                                                    </a> 
                                                </LazyLoad>
                                                </div>
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                                <div className="achievementdesc">
                                                    <p><i className="bi bi-calendar"></i> {item.day}.{item.month}</p>
                                                     
                                                </div>
                                                <div className="clr"></div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Achievements;
