import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
import LazyLoad from 'react-lazyload';
const SchoolUniform = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
     <div className="row bread_cumb">
     <h2>School Uniform</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>Prospectus <i className="bi bi-arrow-right"></i> School Uniform</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12">
<div className="clr"></div>
<div className="row">
<div className="col-lg-4 col-md-6 col-sm-6 col-6"> <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/uniform.jpg" className="img-fluid uniformimg" alt="St. Xaviers Bathinda"/></SlideshowLightbox></LazyLoad></div>
<div className="col-lg-4 col-md-6 col-sm-6 col-6"> <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/uniform3.jpg" className="img-fluid uniformimg" alt="St. Xaviers Bathinda"/></SlideshowLightbox></LazyLoad></div>
<div className="col-lg-4 col-md-6 col-sm-6 col-6"> <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/uniform1.jpg" className="img-fluid uniformimg" alt="St. Xaviers Bathinda"/></SlideshowLightbox></LazyLoad></div>
<div className="col-lg-4 col-md-6 col-sm-6 col-6"> <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/uniform2.jpg" className="img-fluid uniformimg" alt="St. Xaviers Bathinda"/></SlideshowLightbox></LazyLoad></div>
    </div>    
</div>
<ProspectSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default SchoolUniform
