import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import MessageSidebar from'./MessageSidebar'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const SupervisorMessage = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/>  </LazyLoad>
     <div className="row bread_cumb">
     <h2>Supervisor's Message</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i></Link><span>Message <i className="bi bi-arrow-right"></i> Supervisor's Message</span></h5>
     </div>
     </div>
     <div className="clr"></div>
     <div className="innerpage">
     <div className="row inner_cont">
     <div className="col-lg-9 col-md-8 col-sm-12 col-12 message_inner">
     <article>
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Supervisor.jpeg" className="img-fluid" alt="St. Xaviers Bathinda" /></LazyLoad>
     <h3>Supervisor's Message (Senior Wing)</h3>
     <p><strong>“Our greatest natural resource is the minds of our children.”</strong></p>
     <p>To shape and mould the future of a child is a noble task entrusted to every adult. Biologically God has entrusted this task to parents and logically they have made us co-responsible for the development of their child.  Thank you for honoring us. We at St. Xavier’s treat every child in a special way. We help in building a strong foundation for our students, not only towards the intellectual growth but towards the holistic development of the child, thus making the child a good human being and a responsible citizen of the country.</p>
     <p>Thanks to our teachers in a very special way for thinking differently and doing their bit to defy the traditional methods of teaching and bringing about a revolution in the field of education through innovation. </p>
     <p>My dream of an educational system is one which helps students to identify their true potential and channelize their energies in the best possible way. That is why we are giving so much of importance to extra-curricular activities in our school. The idea is to give the child an opportunity to explore his strengths. The child is allowed to make mistakes knowing that he or she will learn from them so that these mistakes become a stepping stone in his/her life. </p>
     <p>I feel blessed to be an alumni of St. Xavier’s School, a teacher and now a Supervisor of the Senior Wing. As I look back, I realize everyday is a new learning with new experiences and new insights. I am proud of our legacy and the high academic standards set by the Xavierites, expertise of our staff and the uniqueness of what we offer to the society. </p>
     <p>So my dear Xavierites let us move ahead by thinking independently, aiming high and realizing our dreams because it is never too late to focus on our dreams.</p>
     <p>I welcome you all to the journey to aim higher and think bigger.</p>
       <br />
     <center><p><strong>With Regards</strong></p></center>
             <h5><strong>Nupur</strong></h5>
             <h4>Supervisor (Senior Wing)</h4>        
     </article>
     
     <div className="clr"></div>
     </div>
     <MessageSidebar/>
     <div className="clr"></div>
     </div>
     
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
   <Footer/>
  </>
  )
}

export default SupervisorMessage
