import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ActivitiesSidebar from'./ActivitiesSidebar'
import { Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
import LazyLoad from 'react-lazyload';
const NccNavy = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
     <div className="row bread_cumb">
     <h2>Ncc (Navy) </h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Activities <i className="bi bi-arrow-right"></i> Ncc (Navy) </span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 memories_sec">
        <p>National Cadet Core National Army of volunteer yeomen. Xavier's got its allotment in 2019 on 25th march. It's being run by the unit 3 PB Naval Unit NCC under the mentorship of commanding officer Ajay Kumar. NCC NAVY trains 25 students in a session. students are up skilled about the sign language and are taught about the different types of aircraft and ships at marine. Likewise, NAVY students are also rewarded with an 'A' certificate that equally helps them to bag a seat in higher studies at various universities and colleges.</p>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/navy-2021-22.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
              <h4>2021-22</h4>
            </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/navy-2020-21.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
              <h4>2020-21</h4>
            </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/navy-2019-20.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
              <h4>2019-20</h4>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h4>'Swachhata Drive Against the Use of Plastic'</h4>
        <div className="clr"></div>
        <h5>* Banner March</h5>
        <p>NCC Navy cadets did a banner march on the theme of 'Say No to Plastic Bags’. They marched around the school locality for the awareness and the effects of plastics in our environment. The aim of Banner March was to increase the public understanding and shape community perceptions on the dangers of plastic pollution.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                    <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Banner-March-1.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                    <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Banner-March-2.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                    <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Banner-March-3.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                    <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Banner-March-4.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Plog Run</h5>
        <p>The basic tenet of the program is to promote search the drive with fun, easy and expressive ways to keep surrounding clean and green by remaining fit and healthy at the same time. Several sub-themes were also introduced during this activity such as: Plogging Marathon, Plogging Challenge, Pick up & run. Cadets did a wonderful job to collect the plastic trash in school premises and collectively took decision to keep school premises plastic free.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Plog-Run-1.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Plog-Run-2.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Plog-Run-3.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Plog-Run-4.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Oath Taking</h5>
        <p>Oath taking ceremony conducted by the cadets of St. Xavier’s School on Swachhata drive against the use of plastic.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Oath-Taking-1.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Oath-Taking-2.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Nukkad Natak</h5>
        <p>The cadets of St. Xavier’s School participated in Nukkad Natak against the use of plastic.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Nukkad-Natak-1.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Nukkad-Natak-2.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Nukkad-Natak-3.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Nukkad-Natak-4.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Mock Drill</h5>
        <p>NCC Navy Cadets of St. Xavier's School did a mock drill on disaster management under the guidance of 7 BN NDRF Bathinda. The NDRF team and our cadets displayed the assessment that was shown to them. They learned the process for recovery of surface victims and rope rescue techniques.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Mock-Drill-1.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Mock-Drill-2.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Mock-Drill-3.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Mock-Drill-4.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Stubble Burning</h5>
        <p>NCC Navy cadets of St. Xavier's School, Bathinda conducted the Social Service Community Development activity on 'Stubble Burning'. An awareness campaign was organized with creative posters, speeches and articles at a village Bir Behman, Bathinda. The Sarpanch of the Village & other farmers were the part of this Abhiyan.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Stubble-Burning-1.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Stubble-Burning-2.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Earth Day</h5>
        <p>NCC Navy cadets of St. Xavier's School, Bathinda contributed in the making of an eco-friendly garden, in order to make the garden, plastic bottles were used and saplings were planted.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Earth-Day-1.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Earth-Day-2.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Puneet Sagar Abhiyan</h5>
        <p>The National Cadet Corps (NCC) initiated a nationwide flagship campaign ‘Puneet Sagar Abhiyan’, to clean water bodies. NCC Navy Cadets of St. Xavier's School, Bathinda did a cleanliness drive near Bathinda lakes & spread the message to Conserve & Keep Water Clean. The agenda behind  the Abhiyan was to educate the public and sensitize them about 'Swachh Bharat'.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Puneet-Sagar-Abhiyan-1.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Puneet-Sagar-Abhiyan-2.jpg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
      
        <div className="clr"></div>
        <h5>* International Yoga Day</h5>
        <p>International Yoga Day was celebrated by NCC NAVY Cadets of St. Xavier's School, Bathinda to spread awareness about living a fit and healthy life.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/International-Yoga-Day-1.jpeg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/International-Yoga-Day-2.jpeg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/International-Yoga-Day-3.jpeg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/International-Yoga-Day-4.jpeg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
      
        <div className="clr"></div>
        <h5>* Tug of War</h5>
        <p>NCC NAVY Cadets of St. Xavier's School [participated in the Tug of War match with other schools.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                    <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Tug-of-War-1.jpeg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                    <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Tug-of-War-2.jpeg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
      
        <div className="clr"></div>
        <h5>* Annual Training Camp</h5>
        <p>NCC NAVY Cadets of St. Xavier's School, Bathinda attended Annual Training Camp 2022 at Bikhi, Mansa.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Annual-Training-Camp-1.jpeg" className="img-fluid"/></SlideshowLightbox></LazyLoad>
              </center>
            </article>
          </div>
        </div>
      
                <h4>Cleanliness Drive</h4>
      <p>The NCC cadets have been a major part of the Swachh Bharat Mission initiative and have been carrying out these activities as part of Social responsibility and Community development.
In the month of July, the NCC Cadets of St. Xavier's School took upon itself to mobilize its cadets to carry out cleanliness drives in the city's important public place that is Jogger Park.
The main aim behind involving this drive was to sensitize the younger generation by such social activities.</p>

<div className="row">
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Cleanliness-Drive-1.jpg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Cleanliness-Drive-2.jpg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
</div>

      <h4>Anti-Drug Campaign</h4>
      <p>The goal of this campaign was to mobilize support and inspire people to act against drug use. The NCC NAVAL Cadets did Banner March & did a rally around the Jogger Park ,Bathinda.
</p>
  <div className="row">
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Anti-Drug-Campaign-1.jpg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Anti-Drug-Campaign-2.jpg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
</div>

<h4>Azadi Ka Amrit Mahostav- Har Ghar Tiranga </h4>
<p>To celebrate our 75th year of independence with pride our NCC Navy cadets participated in the awareness rally on the occasion of Azadi ka Amrit mahotsav har Ghar tiranga. In this activity NCC cadets of our school did a rally from NCC Navy headquarter to multipurpose stadium of Bathinda under the leadership of Commanding Officer Arvind Kumar. </p>
  <div className="row">
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Azadi-Ka-Amrit-1.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Azadi-Ka-Amrit-2.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
</div>

<h4>Celebration of 75th Year of Independence </h4>
<p>To embrace the 75th year of independence, NCC cadets hoisted the national flag and marched past. NCC cadets took the pledge of Clean & Green India and felt the real importance of freedom and shared their gratitude towards the freedom fighters of India. </p>
  <div className="row">
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/75th-Year-Independence.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
</div>

<h4>Awareness Rally & Poster Making on the theme of Anti-Dengue/Malaria </h4>
<p>As monsoon is retreating in Northern India there is climate change which is perfect ground for several mosquitoes related diseases. To curb this problem, a poster making and awareness rally was organized to clean the areas containing water like water coolers, plants, water at roof etc. Also, Cadets cleaned their homes to make their families aware about dengue and malaria. </p>
  <div className="row">
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Anti-Dengue -1.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Anti-Dengue -2.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
</div>

<h4>Awareness Rally on Green Diwali </h4>
<p>NCC cadets conducted an awareness rally with green posters on the theme of ‘Save Nature’, ‘Say No to Crackers’ to make local residents aware about green crackers and the need to avoid banned crackers to save nature. Students also gave a message to shopkeepers  to encourage the use of environment- friendly paper bags and told them to stay away from plastic. </p>
  <div className="row">
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/diwali-1.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/diwali-2.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/diwali-3.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
</div>



<h4>Beti - Bachao Beti Padhao Abhiyan</h4>
<p>To boost the ongoing movement ‘Beti Bachao, Beti Padhao’, NCC has  organised a poster-making and slogan-writing competition. The NCC NAVY cadets had made beautiful and inspiring posters on this theme to showcase their artistic trait.</p>
  <div className="row">
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Beti-Bachao-Beti-1.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Beti-Bachao-Beti-2.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
</div>
<h4>Puneet Sagar Abhiyan 2022 </h4>
<p>NCC cadets cleaned the water bodies (lakes of Bathinda City) off the plastic & other waste materials. The campaign increased the awareness about the importance of keeping the water bodies free from plastic wastes across the country. Apart from physically cleaning the water bodies, the NCC cadets have also taken up mass awareness campaigns to educate the people regarding the cleanliness of lakes, canals, rivers etc. & the harmful effects of plastic use and the damage plastic is doing to our natural resources. </p>
  <div className="row">
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Puneet-Sagar-Abhiyan-1.jpg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Puneet-Sagar-Abhiyan-2.jpg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
</div>

<h4>Rashtriya Ekta Diwas </h4>
<p>The ‘Rashtriya Ekta Diwas’ commemorating the 147th birth anniversary of Sardar Vallabhbhai Patel   It encouraged the cadets to strengthen the spirit of cooperation, peace and unity. The NCC cadets took the pledge and marched past to stay united and keep India as a dream country of Sardar Vallabh Bhai Patel. </p>
  <div className="row">
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Rashtriya-Ekta-Diwas-1.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Rashtriya-Ekta-Diwas-2.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Rashtriya-Ekta-Diwas-3.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
<LazyLoad><div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Rashtriya-Ekta-Diwas-4.jpeg" className="img-fluid"/></SlideshowLightbox></center></article></div></LazyLoad>
</div>


      
        <div className="clr"></div>
      </div>
<ActivitiesSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default NccNavy
