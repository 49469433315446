import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getGallery } from '../../Service/Api';
import LazyLoad from 'react-lazyload';

const Gallery = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGallery();
        console.log(galleryData);
        setData(galleryData);
      } catch (error) {
        console.error('Failed to fetch gallery data', error);
      }
    };
    fetchData();
  }, []);

  const emptyArray = [
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg", description: "Stay tuned for latest updates" },
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg", description: "Stay tuned for latest updates" },
    { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg", description: "Stay tuned for latest updates" },
  ];

  return (
    <>
      <div className="container juniorcontainer">
        <h1>Our Gallery</h1>
        <div className="row">
          {data.length > 0 ? data.filter(item => item.school === "Junior" && item.category === "PhotoGallery").slice(0, 8).map((item, index) => (
            <div className="col-12 col-sm-12 col-md-3 col-lg-3" key={index}>
              <div className="img_data_sec1 col-lg-12">
                <LazyLoad><img src={item.attachments && item.attachments.length > 0 ? `https://webapi.entab.info/api/image/${item.attachments[0]}` : "https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/common-img-1.jpg"} alt="St. Xaviers Bathinda" /></LazyLoad>
              </div>
            </div>
          )) : 
          emptyArray.map((data, index) => (
            <div className="col-12 col-sm-12 col-md-3 col-lg-3" key={index}>
              <div className="img_data_sec1 col-lg-12">
                <LazyLoad><img src={data.attachments} alt="St. Xaviers Bathinda" /></LazyLoad>
              </div>
            </div>
          ))}
        </div>
        <div className="clr"></div>
        <center>
          <Link className="dedcription-btn" to="/JuniorPhotoGallery">
            <span className="name-descripeion">Our Gallery</span>
            <div className="btn-icon brain"><i className="fas fa-brain"></i></div>
          </Link>
        </center>
      </div>
    </>
  );
}

export default Gallery;
