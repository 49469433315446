import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Committee = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
     <div className="row bread_cumb">
     <h2> Committee</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>Office Bearers <i className="bi bi-arrow-right"></i>  Committee</span></h5>
     </div>
     
     </div>
     <div className="clr"></div>
     <div className="innerpage">
     <div className="row inner_cont">
     <div className="col-lg-9 col-md-8 col-sm-12 col-12">
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">Vigilance Control Team</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td>    	Ms. Nupur </td>
                       <td>	Supervisor </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="4"> MEMBERS:	</td>
                       <td>   	Mr. Bindesar Kumar </td> 
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>  	Mr. Varinder Singh </td> 
                       <td>	Teacher </td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td>   	Ms. Mamta Joshi  </td>
                     <td>	Teacher </td>
                 </tr> 
                 <tr> <td> 5	</td> 
                       <td> 	Mr. Davinderpal Singh </td>
                       <td>	Teacher </td>
                 </tr>
               </tbody>
        </table> 
     </div>
     
     
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">School Emergency Care Support/Response Team</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td>    	Mr. Jagjit Singh </td>
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="4"> MEMBERS:	</td>
                       <td>   Mr. Rajat Taneja  </td> 
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Simarjeet Kaur     </td> 
                       <td>	Teacher </td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td>   Mrs. Bharti           </td>
                     <td>	Teacher </td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">School Premises Safety & Security Audit Committee</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td>    Mr. Sarbjeet Singh  </td>
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="4"> MEMBERS:	</td>
                       <td> Mr. Gursewak </td> 
                       <td>	Technician </td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Gurvinder Kaur Gill    </td> 
                       <td>	Teacher </td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td> Mr. Vijay Bhudev         </td>
                     <td>	Teacher </td>
                 </tr>  
                 <tr> <td>5	</td>
                     <td>Mr. Armeet Singh         </td>
                     <td>	Technician </td>
                 </tr>  
               </tbody>
        </table> 
     </div>
     
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">School  Academic  Committee</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td>  Ms.  Nupur   </td>
                       <td>	Supervisor </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="6"> MEMBERS:	</td>
                       <td> Mrs. Poonam Baghla   </td> 
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Archana Gupta    </td> 
                       <td>	Teacher </td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td> Mrs. Baljeet Kaur        </td>
                     <td>	Teacher </td>
                 </tr>  
                 <tr> <td>5	</td>
                     <td>Mrs. Harnita Azad    </td>
                     <td>	Teacher </td>
                 </tr>  
                  <tr> <td>6	</td>
                     <td>Mrs. Pallavi Jain                </td>
                     <td>	Coordinator Junior Wing </td>
                 </tr> 
                  <tr> <td>7	</td>
                     <td>Mrs. Shilpa Jaidka   </td>
                     <td>	Teacher </td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">Protection of Children from Sexual Offences (POCSO) Committee</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td> Fr. Cidloy Furtado  </td>
                       <td>	Principal </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="7"> MEMBERS:	</td>
                       <td>Mrs. Archana Rajput   </td> 
                       <td>	Coordinator </td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Pushpinder Kaur  </td> 
                       <td>	Teacher </td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td> Mrs. Poonam Baghla </td>
                     <td>	Teacher </td>
                 </tr>  
                 <tr> <td>5	</td>
                     <td>Mrs. Ashu Beniwal</td>
                     <td>	Counsellor </td>
                 </tr>  
                  <tr> <td>6	</td>
                     <td>Mr. Rohit Sharma   </td>
                     <td>	Legal Expert </td>
                 </tr> 
                  <tr> <td>7	</td>
                     <td>Ms. Janvi  </td>
                     <td>	Student </td>
                 </tr> 
                  <tr> <td>8	</td>
                     <td>Mr. Manraj Singh Sandhu </td>
                     <td>	Student </td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">Transportation Committee </th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td> Mr. Davinderpal Singh </td>
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="4"> MEMBERS:	</td>
                       <td>Mr. Rahul Agnihotri </td> 
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Babli Gill  </td> 
                       <td>	Teacher </td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td> Mrs. Rajwinder Kaur  </td>
                     <td>	Teacher </td>
                 </tr>  
                 <tr> <td>5	</td>
                     <td>Mrs. Gagandeep Kaur Sra </td>
                     <td>	Teacher </td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">School Disciplinary Committee</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td> Mrs. Vineet Dua  </td>
                       <td>	Counsellor </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="4"> MEMBERS:	</td>
                       <td>Mrs. Archana Rajput</td> 
                       <td>	Coordinator Senior Wing </td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Sonia Jindal </td> 
                       <td>	Teacher </td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td>Mrs. Pallavi Jain  </td>
                     <td>	Coordinator Junior Wing </td>
                 </tr>  
                 <tr> <td>5	</td>
                     <td>Mr. Rajat Taneja </td>
                     <td>	Teacher </td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">School Grievance Committee</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td> Mrs. Archana Rajput     </td>
                       <td>	Coordinator Senior Wing </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="4"> MEMBERS:	</td>
                       <td>Mrs. Poonam Baghla</td> 
                       <td>	Teacher</td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Neha Sharma  </td> 
                       <td>Teacher </td> 
                  </tr> 
               </tbody>
        </table> 
     </div>
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">School Fee Committee</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td> Fr. Amaro Martins      </td>
                       <td>	Manager </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="5"> MEMBERS:	</td>
                       <td>Mrs. Simi John </td> 
                       <td>	Office Administrator</td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Pallavi Jain </td> 
                       <td>	Coordinator Junior Wing </td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td>Mrs. Archana Rajput  </td>
                     <td>	Coordinator Senior Wing</td>
                 </tr>  
                 <tr> <td>5	</td>
                     <td>Mrs. Avneet Kaur Sidhu </td>
                     <td>	Parent </td>
                 </tr> 
                  <tr> <td>6	</td>
                     <td>Mr. Rohit Sharma</td>
                     <td>	Parent </td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">Hygiene Inspection Team</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td>Mrs. Sunita Goyal</td>
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="4"> MEMBERS:	</td>
                       <td>Mrs. Archana Gupta</td> 
                       <td>	Teacher</td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Meenakshi Wadhawan</td> 
                       <td>Teacher</td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td>Mrs. Maninderjit Kaur </td>
                     <td>	Teacher</td>
                 </tr>  
                 <tr> <td>5	</td>
                     <td>Mrs. Pushpinder Kaur</td>
                     <td>	Teacher </td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">Internal Complaint’s Committee (ICC in terms of sexual harassment of women at workplace – prevention, prohibition and redressal)</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td>Mrs. Vineet Dua</td>
                       <td>	Counsellor </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="5"> MEMBERS:	</td>
                       <td>Mr. Rohit Sharma</td> 
                       <td>	Legal Expert</td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Ashu Beniwal       </td> 
                       <td>Counsellor</td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td>Mrs. Gurvinder Kaur Gill</td>
                     <td>	Teacher</td>
                 </tr>  
                 <tr> <td>5	</td>
                     <td>Sr. Ophilia Lobo</td>
                     <td>	NGO – Bethany Society </td>
                 </tr> 
                  <tr> <td>6	</td>
                     <td>Dr. Rohit         </td>
                     <td>	Medical Officer </td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">Anti Bullying Committee</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td>Mrs. Asha Garg</td>
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="4"> MEMBERS:	</td>
                       <td>Ms. Jaspal Kaur</td> 
                       <td>	Teacher</td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Archana Gupta  </td> 
                       <td>Teacher</td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td>Mr. Bindesar Kumar</td>
                     <td>	Teacher</td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">Cyber Safety and Security Committee</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td>Mr. Jagjit Singh</td>
                       <td>	Teacher </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td rowspan="4"> MEMBERS:	</td>
                       <td>Mrs. Rashmi Papreja</td> 
                       <td>	Teacher</td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td>Mrs. Madhavi Das       </td> 
                       <td>Teacher</td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td>Mr. Rajat Taneja</td>
                     <td>	Teacher</td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     
     <div className="innertable">
        <table width="100%"> 
             <thead>
                <tr> <th colspan="4">School Level Committee (Child Sexual Abuse/Harassment</th> </tr>
              </thead> 
              <tbody>
                  <tr className="tablebg">
                    <td>S. No.	</td>
                    <td></td> 
                    <td>	Name </td> 
                    <td>	Designation </td> 
                  </tr> 
                  <tr> <td> 1	</td> 
                       <td> CHAIR:	</td> 
                       <td>Fr. Cidloy Furtado</td>
                       <td>	Principal </td> 
                  </tr> 
                  <tr> <td> 2	</td> 
                       <td>VICE CHAIR:	</td>
                       <td>Ms. Nupur </td> 
                       <td>	Supervisor</td> 
                  </tr> 
                  <tr> <td> 3	</td>
                       <td rowspan="4"> MEMBERS:	</td>
                       <td>Mrs. Archana Rajput       </td> 
                       <td>Coordinator/School Management committee member</td> 
                  </tr>
                <tr> <td> 4	</td>
                     <td>Mrs. Pallavi Garg</td>
                     <td>	Teacher</td>
                 </tr> 
                  <tr> <td> 5	</td>
                     <td>Mrs. Arshpreet Kaur</td>
                     <td>	Teacher</td>
                 </tr> 
                  <tr> <td> 6	</td>
                     <td>Mrs. Ashu Beniwal</td>
                     <td>	Counsellor</td>
                 </tr> 
               </tbody>
        </table> 
     </div>
     
     <div className="clr"></div>
     </div>
     <div className="col-lg-3 col-md-4 col-sm-12 col-12">
     <div className="inner_menu">
     <h3>Menu Choice</h3>
     <ul>
     <li><Link id="A2" to="/ManagementCommittee">Management Committee</Link> </li>
     <li><a id="A4" href="/Committee" class="inner_active">committee</a> </li>
     <li><Link id="A6" to="/SchoolParliament">School Parliament</Link></li>
     </ul>
     <div className="clr"></div>
     </div>
     <div className="important_menu">
     <h3>Important Menu</h3>
     <ul>
     <li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
     <li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
     <li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
     <li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
     <li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
     <li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
     </ul>
     <div className="clr"></div>
     </div>
     
     </div>
     <div className="clr"></div>
     </div>
     
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
   <Footer/>
  </>
  )
}

export default Committee
