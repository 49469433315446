import React, { useEffect, useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom';
import { getCircular } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const Magazine = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [yearRanges, setYearRanges] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const handleMonth = (month) => {
        return monthsOfYear[month - 1];
    };

    const emptyArray = [
        { title: "Circular Title" },
        { title: "Circular Title" },
    ];

    useEffect(() => {
      if(filterData) {
            console.log(filterData);
      }
    }, [filterData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryData = await getCircular();
                console.log("Fetched data:", galleryData); // Debugging log
                setData(galleryData);

                const uniqueYears = Array.from(
                    new Set(galleryData.flatMap((item) => {
                        if (item.fromDate) {
                            const year = new Date(item.fromDate).getFullYear();
                            return [year];
                        } else {
                            return [];
                        }
                    }))
                ).sort((a, b) => b - a);

                setYearRanges(uniqueYears);
            } catch (error) {
                console.error("Error fetching data:", error); // Debugging log
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;
        let monthsSet = new Set();

        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate) {
                    const year = new Date(item.fromDate).getFullYear();
                    if (parseInt(selectedYear) === year) {
                        const month = new Date(item.fromDate).getMonth() + 1;
                        monthsSet.add(month);
                        return true;
                    }
                    return false;
                }
                return false;
            });
        } else {
            filteredData.forEach(item => {
                if (item.fromDate) {
                    const month = new Date(item.fromDate).getMonth() + 1;
                    monthsSet.add(month);
                }
            });
        }

        setAvailableMonths(Array.from(monthsSet).sort((a, b) => a - b));

        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.fromDate) {
                    const month = new Date(item.fromDate).getMonth() + 1;
                    return parseInt(selectedMonth) === month;
                } else {
                    return false;
                }
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filteredData);
        console.log("Filtered data:", filteredData); // Debugging log
    }, [selectedYear, selectedMonth, searchQuery, data]);

    return (
        <>
            <Header />
            <div className="inner_slider">
                <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda" /></LazyLoad>
                <div className="row bread_cumb">
                    <h2>Magazine</h2>
                    <div className="theme-heading">
                        <span className="colorW marB10"><i className="bi bi-building"></i></span>
                    </div>
                    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Magazines <i className="bi bi-arrow-right"></i> Magazine</span></h5>
                </div>     
            </div>
            <div className="innerpage inner_cont circular_sec">
                <div className="row tabs-dynamic">
                    {/* <div className="col-md-3">
                        <div className="count-val">
                            <p>Total Count: {filterData.length}</p>
                        </div>
                    </div> */}
                    {/* <div className="col-md-3">
                        <div className="month-selection">
                            <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                <option value="All">All Years</option>
                                {yearRanges.map((yr, index) => (
                                    <option key={index} value={yr}>{yr}</option>
                                ))}
                            </select>
                        </div>
                    </div> */}
                    {/* <div className="col-md-3">
                        <div className="month-selection">
                            <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                <option value="All">All Months</option>
                                {availableMonths.map((month, index) => (
                                    <option key={index} value={month}>{handleMonth(month)}</option>
                                ))}
                            </select>
                        </div>
                    </div> */}

                    <div className="col-md-3">
                        <div className="searchBox">
                            <input 
                                type="text" 
                                id="myInput" 
                                name="name" 
                                autoComplete="off" 
                                placeholder="Search Here.." 
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="clr"></div>
                <div className="row">
                    {filterData?.length > 0 ? filterData?.filter((item) => item.school === "Senior" && item.category === "Magazine").map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth">
                            <article>
                                <Link to={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} target="_blank">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/magzine.png" className="img-fluid" alt="St. Xaviers Bathinda" /></LazyLoad>
                                    <h5>View</h5><h4> {item.title}</h4>
                                </Link>
                                
                            </article>
                        </div>
                    ))
                    :
                    emptyArray.map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth">
                            <article>
                                <Link to="#" target="_blank">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/magzine.png" className="img-fluid" alt="St. Xaviers Bathinda" /></LazyLoad>
                                    <h5>View</h5><h4> {item.title} (DD.MM.YY)</h4>
                                </Link>
                            </article>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Magazine;
