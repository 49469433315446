import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import BulletinSidebar from'./BulletinSidebar'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Students = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
     <div className="row bread_cumb">
     <h2>Students</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Bulletin <i className="bi bi-arrow-right"></i>  Seminars, Webinars, Conferences    <i className="bi bi-arrow-right"></i> Students</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 circular_sec">
        <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Health-Awareness-Camp.pdf" target="_blank"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Health Awareness Camp</h4>
              </Link> </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Career-Choices-in-Armed-Forces.pdf" target="_blank"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Career Choices in Armed Forces</h4>
               </Link> </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Awareness-Program-on-Menstrual-Health-and-Hygiene-Class-VI-VII.pdf" target="_blank"> <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Awareness Program on Menstrual Health and Hygiene (Class VI and VII)</h4>
               </Link> </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Awareness-Program-on-Menstrual-Health-and-Hygiene-Class-VIII-IX.pdf" target="_blank"> <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Awareness Program on Menstrual Health and Hygiene (Class VIII and IX)</h4>
               </Link> </article>
          </div>
          
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/investiture-ceremony-2022.pdf" target="_blank"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Investiture Ceremony</h4>
               </Link> </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Aqua-Fun-Day.pdf" target="_blank"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Aqua Fun Day</h4>
               </Link> </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Summer-Fiesta-Little-Guiding-Lights.pdf" target="_blank"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Summer Fiesta-Little Guiding Lights</h4>
               </Link> </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Celebrating-Motherhood-MomMe.pdf" target="_blank"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Celebrating Motherhood: MomMe </h4>
               </Link> </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Parent-Orientation-Programme.pdf" target="_blank"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Parent Orientation Programme </h4>
               </Link> </article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="https://webapi.entab.info/api/image/SXSBPB/public/pdf/careerguidance-students.pdf" target="_blank"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/></LazyLoad>
              <h5>View</h5>
              <h4>Career Guidance and Awareness Programme </h4>
               </Link> </article>
          </div>
        </div>
        <div className="clr"></div>
      </div>
<BulletinSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default Students
