import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import MessageSidebar from'./MessageSidebar'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const JuniorWingCoordinatorMessage = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/>  </LazyLoad>
     <div className="row bread_cumb">
     <h2>Coordinator's Message (Junior Wing)</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>Message <i className="bi bi-arrow-right"></i> Coordinator's Message (Junior Wing)</span></h5>
     </div>
     </div>
     <div className="clr"></div>
     <div className="innerpage">
     <div className="row inner_cont">
     <div className="col-lg-9 col-md-8 col-sm-12 col-12 message_inner">
     <article>
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Coordinator Junior Wing.JPG" className="img-fluid"  alt="St. Xaviers Bathinda" /></LazyLoad>
     <h3>Coordinator's Message (Junior Wing)</h3>
     <p>For all that was, that is and that will be I thank My God.</p>
     <p>Every good gift and every perfect gift is from above, and cometh down from the Father of lights, with whom is no variableness, neither shadow of turning. - James 1:17</p>
     <p>Learning is the process of constantly updating in an ever changing world. The first five year experience embodies the most rapid changes of life and are critical in a child’s development. All children should be given the opportunity to experience the very best possible start to their education. From a very young age we bring children, through purposeful activities, to independent learning and self-initiative, enabling them to achieve their highest potential. Self-esteem is achieved and skills vital to a fully functioning member of society are developed. Learning at our school is based on the principles of; teaching the children to be courageous, confident, disciplined, responsible and loyal.</p>
     <p>There is equal emphasis on academic rigour and engagements beyond the classroom through a wide spectrum of opportunities.</p>
     <p> “Never stop fighting until you arrive at your destined place – that is, the unique you. Have an aim in life, continuously acquire knowledge, work hard, and have perseverance to realise the great life.” Says, Dr.  A. P. J. Abdul Kalam. Even as we impart the education to match the advancement in technology and processes, we guide our children towards right principles, ethics and moral values. 
     </p>
     <p>“Education is not preparation for life; Education is life itself.” If this sounds like the kind of environment that would suit you I urge you to read more, and I am confident you will join with us in our journey.</p>
     <p>
     A child’s school should be an extension of their family, providing a safe environment in which they can explore ideas and feelings. Parents who enroll their children at St. Xavier’s School, Bathinda are required to attend and participate in all information meetings arranged by the school to foster open communication channels for the benefit of the children. We value regular contact with parents, as an essential part of the education process. We are always looking for new ways to involve parents in the life of the school. We are always open to refreshing views and suggestions which can add more value to the students of our school. I also take this opportunity to thank the parents of our students for having bestowed their faith and confidence in us. Your faith in us is our driving force.
     </p>
     <p>“But I have promises to keep and miles to go before I sleep …………… and miles to go before I sleep.” Much has been done these past years but still more needs to be done in the years to come. May God be our inspiration and guide to help St. Xavier’s achieve and live up to the dreams it was started for.</p>
     <p>I would like to end with the words of Albert Einstein – “Imagination is more important than knowledge, for while knowledge defines all that we currently know and understand, imagination points to all we might yet discover and create.” One of the many things that makes this School such a positive place to be is that we have a strong belief that we are all learners together. The children, teachers and parents are all part of our “Learning Community’ and we strive to work and learn together. </p>
     <br />
     <center><p><strong>Best Wishes</strong></p></center>
             <h5><strong>Pallavi</strong></h5>
             <h4>(Coordinator – Junior Wing)</h4>
             
     </article>
     
     <div className="clr"></div>
     </div>
     <MessageSidebar/>
     <div className="clr"></div>
     </div>
     
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
   <Footer/>
  </>
  )
}

export default JuniorWingCoordinatorMessage
