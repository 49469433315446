import React, { useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from './ProspectSidebar';
import { Link, useNavigate } from 'react-router-dom';
import { postTransferCertificate } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const Tc = () => {
  const navigate = useNavigate();
  const [admission, setAdmission] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleAdmissionNoChange = (event) => {
    setAdmission(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleFullNameChange = (event) => {
    setName(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !admission) {
      setError("Please fill in all fields");
      return;
    }
    
    try {
      const response = await postTransferCertificate(name, admission);
      if (response) {
        console.log(response.data);
        // Potentially navigate or show success message
        fetch(`https://webapi.entab.info/api/image/${response.data}`)
        .then(response => response.blob())  // Assuming the data is in a blob format; adjust if different
        .then(blob => {
            // Create a link element, use it to download the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'tc.jpg';  // Set the file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // alert('Your download has started.');  // Optional: Notify the user
        })
        .catch(() => alert('Failed to download data.'));  // Error handling
      }
    } catch (error) {
      console.error("Failed to post data", error);
      setError("Failed to process your request. Please try again later.");
    }
  };

  return (
    <>
      <Header />
      <div className="inner_slider">
        <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda" /></LazyLoad>
        <div className="row bread_cumb">
          <h2>Transfer Certificate</h2>
          <div className="theme-heading">
            <span className="colorW marB10"><i className="bi bi-building"></i></span>
          </div>
          <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Prospectus <i className="bi bi-arrow-right"></i> Transfer Certificate</span></h5>
        </div>
      </div>
      <div className="innerpage">
        <div className="row inner_cont">
          <div className="col-lg-9 col-md-8 col-sm-12 col-12">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 tcsec">
                <article>
                  <center>
                    <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/tc.jpg" className="img-fluid" alt="St. Xaviers Bathinda" /></LazyLoad>
                  </center>
                </article>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 form_sec">
                <form onSubmit={handleSubmit} className="tc" data-aos="fade-left" data-aos-duration="2000">
                  <div className="text_box_sec"> <input id="name" value={name} onChange={handleFullNameChange} type="text" placeholder="Name" /></div>
                  <div className="text_box_sec"> <input id="admission" value={admission} onChange={handleAdmissionNoChange} type="text" placeholder="Admission Number" /></div>
                  <div className="text_box_sec"><input type="submit" value="VIEW DETAILS" /></div>
                  {error && <p className="error">{error}</p>}
                </form>
              </div>
            </div>
          </div>
          <ProspectSidebar />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Tc;
