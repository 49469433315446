import React from 'react'
import { Link } from 'react-router-dom' 
import Footer from'../Component/Footer'
import { useState } from 'react';
import LazyLoad from 'react-lazyload';
const KidsCorner = () => {
    const [selectedOption ,setselectedOption] = useState('option2022');
    function handleOnchange(e){
       setselectedOption(e.target.value)
    }
  return (
   <>
   <div className="kids">
   <div className="container juniorcontainer">
   <div className="row">
   <div className="col-lg-6 col-md-6 col-12">
   <Link to="/JuniorHome"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/logo.png" className="img-fluid logo" alt="St. Xaviers Bathinda" /></LazyLoad></Link>

   </div>
   <div className="col-lg-6 col-md-6 col-12">
    <Link to="/JuniorHome"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/kids.png" className="img-fluid kids-logo" alt="St. Xaviers Bathinda" /></LazyLoad></Link>
   <div className="kintergarten_slider_sec_btm_bird"> <LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/bird.gif" className="img-fluid"/></LazyLoad></div>
   </div>
   </div>
   <div className="kids-back-sec kid-cor">
   
   
   <div className="news-inner">
   <div className="row">
   <div className="col-lg-12">
   <div className="chooseyear">
   <select id="chooseddlYear" value={selectedOption} onChange={handleOnchange}>
           <option value="option2022">UKG i Vowal Activity</option>
           <option value="option2021">UKG Leap Day 29 Oct</option>
           <option value="option2020">UKG Sports Day</option>
           <option value="option2019">UKG Yoga Day</option>
           <option value="option2018">UKG Diya Decoration</option>
           <option value="option2017">UKG Dental Care</option>
           <option value="option2016">UKG Plasticine Modelling Competition</option>
           <option value="option2015">UKG Teacher day</option>
    </select>
</div>
<div className="clr"></div>
{selectedOption === 'option2022' &&  <div className="row data_show">
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (1).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (1).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (2).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (2).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (3).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (3).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (4).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (4).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (5).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (5).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (6).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (6).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (7).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (7).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (8).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (8).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (9).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (9).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (10).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (10).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (11).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (11).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (12).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (12).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (13).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (13).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (14).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (14).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (15).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (15).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (16).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (16).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (17).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (17).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (18).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (18).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (19).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (19).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (20).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (20).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (21).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (21).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (22).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (22).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (23).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (23).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (24).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (24).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (25).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (25).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (26).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/vowal_ (26).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
</div>}
{selectedOption === 'option2021' &&  <div className="row data_show">
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (1).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (1).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (2).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (2).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (3).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (3).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (4).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (4).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (5).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (5).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (6).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (6).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (7).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (7).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (8).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (8).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (9).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (9).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (10).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (10).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (11).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (11).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (12).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (12).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (13).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (13).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (14).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (14).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (15).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (15).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (16).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (16).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (17).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (17).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (18).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (18).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (19).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (19).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (20).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (20).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (21).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (21).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (22).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (22).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (23).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (23).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (24).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (24).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (25).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (25).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (26).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (26).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (27).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (27).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (28).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (28).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (29).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (29).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (30).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (30).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (31).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (31).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (32).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (32).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (33).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (33).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (34).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (34).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (35).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (35).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (36).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (36).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (37).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (37).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (38).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (38).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (39).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (39).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (40).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (40).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (41).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (41).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (42).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (42).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (43).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (43).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (44).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (44).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (45).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (45).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (46).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (46).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (47).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (47).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (48).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (48).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (49).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/leap_day_ (49).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>

</div>}
{selectedOption === 'option2020' &&  <div className="row data_show">
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (1).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (1).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (2).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (2).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (3).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (3).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (4).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (4).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (5).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (5).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (6).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (6).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (7).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (7).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (8).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (8).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (9).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (9).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (10).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (10).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (11).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (11).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (12).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (12).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (13).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (13).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (14).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (14).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (15).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (15).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (16).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (16).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (17).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (17).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (18).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (18).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (19).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (19).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (20).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (20).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (21).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (21).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (22).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (22).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (23).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (23).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (24).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (24).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (25).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (25).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (26).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (26).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (27).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (27).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (28).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (28).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (29).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (29).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (30).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (30).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (31).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (31).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (32).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (32).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (33).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (33).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (34).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (34).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (35).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (35).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (36).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (36).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (37).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (37).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (38).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (38).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (39).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (39).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (40).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (40).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (41).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (41).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (42).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (42).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (43).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (43).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (44).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (44).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (45).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (45).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (46).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (46).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (47).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (47).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (48).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (48).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (49).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (49).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (50).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (50).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (51).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (51).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (52).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/sports_day_ (52).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>


</div>}
{selectedOption === 'option2019' &&  <div className="row data_show">
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (1).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (1).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (2).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (2).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (3).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (3).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (4).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (4).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (5).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (5).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (6).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (6).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (7).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (7).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (8).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (8).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (9).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (9).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (10).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (10).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (11).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (11).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (12).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (12).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (13).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (13).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (14).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (14).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (15).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (15).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (16).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (16).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (17).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (17).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (18).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (18).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (19).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/yoga_day_ (19).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>

</div>}
{selectedOption === 'option2018' &&  <div className="row data_show">
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (1).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (1).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (2).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (2).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (3).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (3).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (4).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (4).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (5).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (5).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (6).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (6).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (7).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (7).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (8).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (8).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (9).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (9).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (10).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (10).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (11).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (11).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (12).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (12).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (13).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (13).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (14).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (14).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (15).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (15).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (16).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (16).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (17).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (17).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (18).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (18).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (19).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (19).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (20).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (20).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (21).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (21).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (22).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (22).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (23).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (23).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (24).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (24).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (25).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/diya_decoration_ (25).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>




</div>}
{selectedOption === 'option2017' &&  <div className="row data_show">
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (1).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (1).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (2).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (2).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (3).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (3).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (4).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (4).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (5).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (5).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (6).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (6).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (7).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (7).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (8).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (8).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (9).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (9).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (10).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (10).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (11).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (11).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (12).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (12).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (13).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (13).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (14).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (14).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (15).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (15).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (16).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (16).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (17).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (17).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (18).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (18).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (19).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (19).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (20).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (20).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (21).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (21).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (22).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (22).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (23).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/dental_care_ (23).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>

</div>}
{selectedOption === 'option2016' &&  <div className="row data_show">
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (1).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (1).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (2).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (2).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (3).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (3).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (4).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (4).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (5).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (5).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (6).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (6).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (7).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (7).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (8).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (8).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (9).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (9).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (10).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (10).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (11).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (11).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (12).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (12).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (13).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (13).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (14).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (14).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (15).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (15).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (16).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (16).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (17).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (17).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (18).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (18).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (19).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (19).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (20).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (20).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (21).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (21).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (22).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (22).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (23).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (23).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (24).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (24).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (25).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (25).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (26).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (26).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (27).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (27).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (28).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (28).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (29).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (29).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (30).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (30).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (31).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (31).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (32).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (32).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (33).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (33).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (34).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (34).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (35).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (35).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (36).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (36).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (37).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (37).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (38).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (38).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (39).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (39).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (40).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (40).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (41).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (41).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (42).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (42).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (43).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (43).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (44).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (44).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (45).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (45).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (46).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (46).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (47).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (47).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (48).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (48).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (49).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (49).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (50).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (50).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (51).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (51).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (52).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (52).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (53).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (53).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (54).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (54).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (55).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/modelling_competition_ (55).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
</div>}
{selectedOption === 'option2015' &&  <div className="row data_show">
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/teachers_day_ (1).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/teachers_day_ (1).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/teachers_day_ (2).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/teachers_day_ (2).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/teachers_day_ (3).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/teachers_day_ (3).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
<div className="col-xl-3 col-lg-3 col-6"><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/teachers_day_ (41).jpg"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/teachers_day_ (4).jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></LazyLoad></a></div>
</div>}
   </div>
</div> 
          
    </div>
   
   </div>
   </div>
   </div>
   <Footer/>
   </>
  )
}

export default KidsCorner
