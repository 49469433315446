import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import BulletinSidebar from'./BulletinSidebar'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const VoiceSms = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
     <div className="row bread_cumb">
     <h2>Voice Sms</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Bulletin <i className="bi bi-arrow-right"></i>  Gallery <i className="bi bi-arrow-right"></i>  Voice Sms</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 media_sec voice-sec">
<div className="row">
<div className="col-lg-6 col-md-12 col-sm-12 col-12">
<article>
<LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/sound.png" width="512" height="512" layout="responsive" alt="St. Xaviers Bathinda" className="sound_icon"/></LazyLoad>
<p><strong>Voice SMS : </strong> Christmas 2015 & New Year Wishes</p>
<p><strong>Voice of : </strong> Fr. Eulalio Fernandes</p>
<p><strong>Recorded On : </strong> 18-Dec-2015</p>
<p><strong>Sent to Parents On :   </strong> 24-Dec-2018</p>
<div className="clr10"></div>
<audio src="https://webapi.entab.info/api/image/SXSBPB/public/Images/audio/03-St.Xavier's_Bathinda_Fr.EulalioFernandes_Christmas2015.mp3" controls preload></audio>
</article>
</div>
<div className="col-lg-6 col-md-12 col-sm-12 col-12">
<article>
<LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/sound.png" width="512" height="512" layout="responsive" alt="St. Xaviers Bathinda" className="sound_icon"/></LazyLoad>
<p><strong>Voice SMS : </strong> Holi</p>
<p><strong>Voice of : </strong> Fr. Eulalio Fernandes</p>
<p><strong>Recorded On : </strong> 03-Mar-2015</p>
<p><strong>Sent to Parents On :   </strong> 05-Mar-2015</p>
<div className="clr10"></div>
<audio src="https://webapi.entab.info/api/image/SXSBPB/public/Images/audio/06_St.XaviersSchool_Bathinda_FEulalioFernandes_Holi2015.mp3" controls preload></audio>
</article>
</div>
</div>
</div>
<BulletinSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default VoiceSms
