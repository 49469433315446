import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';

const FeeRules = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
    <div className="row bread_cumb">
    <h2>Fee Rules & Structure</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Fee Rules & Structure</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <h3>FEE NORMS</h3>
   <ul>
   <li>Fee charged by the school is as per the norms fixed by the state government. It is in commensurate to the infrastructure and facilities offered by the school.</li>
   </ul>
   <h3>FEE RULES</h3>
   <ul>
   <li>Initial payment at the time of admission is to be paid in cash in the Bank / Online.</li>
   <li>Caution money is to insure the school against non-payment or irregular payment of fees and other dues. After deduction of due arrears, the caution money will be refunded when the pupil leaves the school.</li>
   <li>A pay-in slip booklet is issued for depositing the fees with the bank. This booklet shall be collected by parents/guardian from the office of the school at the beginning of the academic year, or at the time of admission in the case of new entrant. Parents should ensure that the name, the allotted admission number and class/section and Roll No. of the student are correctly entered in the pay - in slip in order to avoid any inconvenience. The school does not stand responsible for payment not credited to the school account due to inadequate/erratic entries in the pay - in - slip. Maintain the fee slip for the entire academic year and present it on every parent teacher meet day for verification.</li>
   <li>Fee once paid are not refundable.</li>
   <li>The amount of fee and other charges payable by the students and the mode and time of payment is duly mentioned in the fee booklet issued to each student every year. Parents are advised to adhere to the schedule of payment of fees and charges, strictly. Penalty will be imposed for the delay in payment of fee.</li>
   <li>Fee and other charges will have to be paid as long as a pupil's name is on the roll.</li>
   <li>Failure to pay the fee, dues and other charges before the commencement of the examinations may debar a student from it. In all case, the school management shall withhold the result and the concerned student will not be eligible for promotion to the next class unless the dues are cleared up within the time stipulated by the school management.</li>
   <li>A fee will be charged for any extract from the school records.</li>
   <li><b>The school management reserves the right to modify or enhance to the minimum of 10%, the amount of fee and other charges annually. The said modification/enhancement shall become due and payable with prospective effect.</b></li>
   </ul> 
   <h3>FEE STRUCTURE</h3>
   <iframe src="https://webapi.entab.info/api/image/SXSBPB/public/pdf/Fee-Structure-school.pdf" width="100%" height="700px"></iframe>
   
   <div className="clr"></div>
   
   <div className="clr"></div>
   </div>
<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default FeeRules
