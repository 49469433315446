import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {NavLink, Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
import LazyLoad from 'react-lazyload';
const ErpSoftware = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
     <div className="row bread_cumb">
     <h2>Erp Software </h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>   Erp Software </span></h5>
     </div>
     
     </div>
     <div className="innerpage">
        <div className="row inner_cont erp_sec">
           <div className="col-lg-9 col-md-8 col-sm-12 col-12">
           <div className="row">
           <div className="col-12 col-sm-12 col-md-9">
                         <div className="row">
                         <div className="col-12 col-sm-12 col-md-12">
                         <p className="details"> Entab Infotech Pvt Ltd., a company formed in 2000 and serving the school with automation technologies has evolved out as the best service provider through their advanced <Link to="https://www.entab.in/school-management-erp.html"> <b>ERP Software:</b></Link> 'CampusCare®' and communication tools. We are also proud to be associated with them for managing the data of students, exam, fees, staff and  their accounts etc in addition to the <Link to="https://www.entab.in/school-communication-portals.html"> <b> communication portal</b></Link> and <Link to="https://www.entab.in/mobile-apps.html"> <b> mobile apps</b></Link>.<br/>
                         <br/>
                         The entire system is strengthening the academic of the students and preparing responsible staff and students. Together the reputation of the school grows higher. We value the relation with  <Link to="https://www.entab.in/"> <b>Entab: CampusCare®</b></Link> and look forward to receive the much more in technological front.</p>
                          </div>
                         </div>
                         <div className="clr25"></div>
                         <div className="row"> 
                              
                              <div className="col-12 col-sm-4 col-md-4">
                                   <h4 className="imgheading"><Link to="https://www.entab.in/school-management-erp.html"> Manage Data</Link></h4>
                                   
                                   <LazyLoad><SlideshowLightbox><img id="myImg" src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Manage-Data.gif" className="img-fluid" alt="School Management System, Management ERP Software"/></SlideshowLightbox></LazyLoad>
                                  
                                   
                              </div>
                              <div className="col-12 col-sm-4 col-md-4">
                                   <h4 className="imgheading"><Link to="https://www.entab.in/"> Parent Portal</Link></h4>
                                   <LazyLoad><SlideshowLightbox><img id="myImg1" lightbox src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Parent-Portal.gif" className="img-fluid" alt="Entab Parent Portal, School Software"/></SlideshowLightbox></LazyLoad>
                                   
                              </div>
                              <div className="col-12 col-sm-4 col-md-4">
                                   <h4 className="imgheading"><Link to="https://www.entab.in/"> Communication Portal</Link></h4>
                                   <LazyLoad><SlideshowLightbox><img id="myImg2" src="https://webapi.entab.info/api/image/SXSBPB/public/Images/communication.gif"  className="img-fluid" alt="Entab Parent Portal"/></SlideshowLightbox></LazyLoad>
                                                            
                              </div>
                         </div>
                    </div>
          <div className="col-12 col-sm-12 col-md-3">
                         <h4 className="imgheading"><Link to="https://www.entab.in/mobile-apps.html">CampusCare</Link></h4>
                        <center><Link to="https://www.entab.in/mobile-apps.html">
                         <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/Mobile-slides.gif" className="img-fluid" alt="School ERP Software, Mobile App, School Management Software, Software for School"/></LazyLoad>
                         </Link> </center>
                        </div>
          </div>
           </div>

           <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                    <div className="important_menu">
                    <h3>Important Menu</h3>
                    <ul>
                    <li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
                    <li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
                    <li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
                    <li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
                    <li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
                    <li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
                    </ul>
                    <div className="clr"></div>
                    </div>
             </div>
        </div>
      </div>
   <Footer/>
  </>
  )
}

export default ErpSoftware
