import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const ManagementCommittee = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
     <div className="row bread_cumb">
     <h2>Management Committee</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>Office Bearers <i className="bi bi-arrow-right"></i> Management Committee</span></h5>
     </div>
     
     </div>
     <div className="clr"></div>
     <div className="innerpage">
     <div className="row inner_cont">
     <div className="col-lg-9 col-md-8 col-sm-12 col-12">
     <div className="innertable">
     <table width="100%">
     <tbody><tr>
     <th>SR. NO. </th>
     <th>NAME </th>
     <th>DESIGNATION </th>
     </tr>
     <tr>
     <td>1</td>
     <td>FR. REMY CARDOZO </td>
     <td>PRESIDENT</td>
     </tr>
     <tr>
     <td>2</td>
     <td>FR. JERRY LOBO</td>
     <td>COORDINATOR OF EDUCATION</td>
     </tr>
     <tr>
     <td>3</td>
     <td> Fr. AMARO MARTINS </td>
     <td>MANAGER</td>
     </tr>
     <tr>
     <td>4</td>
     <td>FR. CIDLOY FURTADO </td>
     <td>PRINCIPAL CUM SECRETARY</td>
     </tr>
     <tr>
     <td>5</td>
     <td> FR. EULALIO  JULIO  FERNANDES</td>
     <td> LOCAL SUPERIOR</td>
     </tr>
     <tr>
     <td>6</td>
     <td> FR. IVO DIAS</td>
     <td>PARISH PRIEST</td>
     </tr>
     <tr>
     <td>7</td>
     <td>FR. ANTHONY M. FERNANDES </td>
     <td>MEMBER</td>
     </tr>
     <tr>
     <td>8</td>
     <td>SR. OPHILIA LOBO </td>
     <td>MEMBER</td>
     </tr>
     <tr>
     <td>9</td>
     <td>MS. VIVYA ARORA </td>
     <td>MEMBER </td>
     </tr>
     <tr>
     <td>10</td>
     <td>MRS. SMRITI BRAR </td>
     <td>MEMBER </td>
     </tr>
     <tr>
     <td>11</td>
     <td>MRS. REETINDER KAUR </td>
     <td> MEMBER</td>
     </tr>
     <tr>
     <td>12</td>
     <td>MRS. ARCHANA RAJPUT </td>
     <td>TEACHER REPRESENTATIVE</td>
     </tr>
     <tr>
     <td>13</td>
     <td>MRS. VINEET DUA </td>
     <td>TEACHER REPRESENTATIVE</td>
     </tr>
     <tr>
     <td>14</td>
     <td>MRS. AVNEET KAUR SIDHU </td>
     <td>PARENT REPRESENTATIVE</td>
     </tr>
     <tr>
     <td>15</td>
     <td>ADV. ROHIT SHARMA </td>
     <td>PARENT REPRESENTATIVE</td>
     </tr>
     </tbody></table>
     </div>
     
     
     <div className="clr"></div>
     </div>
     <div className="col-lg-3 col-md-4 col-sm-12 col-12">
     <div className="inner_menu">
     <h3>Menu Choice</h3>
     <ul>
     <li><Link id="A2" to="ManagementCommittee" className="inner_active">Management Committee</Link> </li>
    
     <li><Link id="A6" to="/SchoolParliament">School Parliament</Link></li>
     </ul>
     <div className="clr"></div>
     </div>
     <div className="important_menu">
     <h3>Important Menu</h3>
     <ul>
     <li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
     <li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
     <li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
     <li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
     <li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
     <li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
     </ul>
     <div className="clr"></div>
     </div>
     
     </div>
     <div className="clr"></div>
     </div>
     
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
   <Footer/>
  </>
  )
}

export default ManagementCommittee
