import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Component/Footer';
import { getGallery } from '../../Service/Api';
import LazyLoad from 'react-lazyload';

const LeapDay = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedMonth, setSelectedMonth] = useState("0");
  const [searchQuery, setSearchQuery] = useState("");
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGallery();
        setData(galleryData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let filtered = data;

    if (selectedYear !== "All") {
      filtered = filtered.filter(item => new Date(item.date).getFullYear() === parseInt(selectedYear));
    }

    if (selectedMonth !== "0") {
      filtered = filtered.filter(item => new Date(item.date).getMonth() + 1 === parseInt(selectedMonth));
    }

    if (searchQuery) {
      filtered = filtered.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    setFilteredData(filtered);

    const uniqueYears = Array.from(new Set(filtered.map(item => new Date(item.date).getFullYear())));
    uniqueYears.sort((a, b) => b - a);
    setYears(uniqueYears);

    const availableMonths = Array.from(new Set(filtered.map(item => new Date(item.date).getMonth() + 1)));
    availableMonths.sort((a, b) => a - b);
    setMonths(availableMonths);
  }, [selectedYear, selectedMonth, searchQuery, data]);

  const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="kids">
        <div className="container juniorcontainer">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <Link to="/JuniorHome"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/logo.png" className="img-fluid logo" alt="St. Xaviers Bathinda" /></LazyLoad></Link>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <Link to="/JuniorHome"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/kids.png" className="img-fluid kids-logo" alt="St. Xaviers Bathinda" /></LazyLoad></Link>
              <div className="kintergarten_slider_sec_btm_bird"><LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/bird.gif" className="img-fluid" alt="Bird Animation" /></LazyLoad></div>
            </div>
          </div>
          <div className="kids-back-sec">
            <h2>Leap Day</h2>
            <div className="news-inner">
              {/*   <div className="row tabs-dynamic">
                <div className="col-md-4">
                  <div className="month-selection">
                    <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                      <option value="All">All Years</option>
                      {years.map((year, index) => (
                        <option key={index} value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="month-selection">
                    <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                      <option value="0">All Months</option>
                      {months.map((month, index) => (
                        <option key={index} value={month}>{monthsOfYear[month - 1]}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="searchBox">
                    <input 
                      type="text" 
                      id="myInput" 
                      name="name" 
                      autoComplete="off" 
                      placeholder="Search Here.." 
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>  */}
              <div className="row">
                {filteredData.length > 0 ? (
                  filteredData
                    .filter(item => item.school === "Junior" && item.category === "KidsCorner")
                    .map((item, index) => (
                      <div key={index} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 galleryCount">
                        <h6>{item.title}</h6>
                        <div className="row">
                          {item.attachments.map((attachment, idx) => (
                            <div key={idx} className="col-xl-3 col-lg-3 col-md-3 col-sm-3 galleryCount">
                              <div className="gallery-inner">
                                <div className="gallery-blk">
                                  <a data-magnify="gallery" data-src="" data-group="a" href={`https://webapi.entab.info/api/image/${attachment}`}>
                                    <LazyLoad><img src={`https://webapi.entab.info/api/image/${attachment}`} className="img-fluid" alt="St. Xaviers Bathinda" /></LazyLoad>
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 galleryCount">
                    <h6>No Data Available</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LeapDay;
