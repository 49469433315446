import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {NavLink, Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Speech = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> </LazyLoad>
     <div className="row bread_cumb">
     <h2>Speech</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Bulletin <i className="bi bi-arrow-right"></i>   <i className="bi bi-arrow-right"></i> Speech</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 circular_sec">
<LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/coming-soon.jpg" alt="St. Xaviers Bathinda" className="coming_soon img-fluid"/></LazyLoad>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
        <div className="important_menu">
        <h3>Important Menu</h3>
        <ul>
        <li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
        <li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
        <li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
        <li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
        <li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
        <li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
        </ul>
        <div className="clr"></div>
        </div>
 </div>
</div>
</div>
   <Footer/>
  </>
  )
}

export default Speech
