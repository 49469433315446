import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import Footer from'../Component/Footer'
import { getCircular } from '../../Service/Api';
import LazyLoad from 'react-lazyload';
const JuniorCircular = () => {
      const [selectedOption ,setselectedOption] = useState('option2024');
      function handleOnchange(e){
         setselectedOption(e.target.value)
      }

      const [data, setData] = useState([]);
      const [filterData, setFilteredData] = useState([]);
      const [selectedYear, setSelectedYear] = useState("All");
      const [selectedMonth, setSelectedMonth] = useState("All");
      const [monthOptions, setMonthOptions] = useState(["All"]);
      const [yearRanges, setYearRanges] = useState([]);
      const [searchQuery, setSearchQuery] = useState("");
  
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
      useEffect(() => {
          const fetchData = async () => {
              const galleryData = await getCircular();
              setData(galleryData);
  
              const uniqueYears = Array.from(new Set(galleryData.map(item => new Date(item.date).getFullYear())));
              uniqueYears.sort((a, b) => b - a);
              setYearRanges(uniqueYears);
  
              const uniqueMonths = Array.from(new Set(galleryData.map(item => new Date(item.date).getMonth())));
              uniqueMonths.sort((a, b) => a - b);
              setMonthOptions(["All", ...uniqueMonths.map(month => monthNames[month])]);
          };
          fetchData();
      }, []);
  
      useEffect(() => {
          let filteredData = data;
  
          if (selectedYear !== "All") {
              filteredData = filteredData.filter(item => {
                  if (item.date) {
                      const year = new Date(item.date).getFullYear();
                      return parseInt(selectedYear) === year;
                  }
                  return false;
              });
          }
  
          if (selectedMonth !== "All") {
              filteredData = filteredData.filter(item => {
                  if (item.date) {
                      const month = new Date(item.date).getMonth();
                      return monthNames[month] === selectedMonth;
                  }
                  return false;
              });
          }
  
          if (searchQuery) {
              filteredData = filteredData.filter(item =>
                  item.title.toLowerCase().includes(searchQuery.toLowerCase())
              );
          }
  
          setFilteredData(filteredData);
      }, [selectedYear, selectedMonth, searchQuery, data]);
  
      const emptyArray = [
          { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
          { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
          { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
          { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/SXSBPB/public/Images/gallery-pic.jpg" },
      ];
  
      const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = date.getDate();
          const month = monthNames[date.getMonth()];
          const year = date.getFullYear();
          return `${day} ${month} ${year}`;
      };
  
  
  return (
   <>
   <div className="kids">
   <div className="container juniorcontainer">
   <div className="row">
   <div className="col-lg-6 col-md-6 col-12">
   <Link to="/JuniorHome">
    <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/logo.png" className="img-fluid logo" alt="St. Xaviers Bathinda" /></LazyLoad>
    </Link>

   </div>
   <div className="col-lg-6 col-md-6 col-12">
    <Link to="/JuniorHome"><LazyLoad> <img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/kids.png" className="img-fluid kids-logo" alt="St. Xaviers Bathinda" /></LazyLoad>
    </Link>
   <div className="kintergarten_slider_sec_btm_bird">
    <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/JuniorImages/bird.gif" className="img-fluid"/></LazyLoad></div>
   </div>
   </div>
   <div className="kids-back-sec">
   <h2> Circular</h2>
   
   <div className="news-inner">
   <div className="row tabs-dynamic">
                        {/* <div className="col-md-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div> */}
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                    <option value="All">All Years</option>
                                    {yearRanges.map((yr, index) => (
                                        <option key={index} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="month-selection">
                                <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                    <option value="All">All Months</option>
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div> */}
                        <div className="col-md-3">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    autoComplete="off"
                                    placeholder="Search Here.."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
   <div className="row circular_sec">
   <div className="col-lg-12">

<div className="clr"></div>
{filterData?.length > 0 ? filterData?.filter((item) => item.school === "Junior" && item.category === "Circular").map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth">
                            <article>
                                <Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/magzine.png" className="img-fluid" alt="St. Xaviers Bathinda" /></LazyLoad>
                                    <h5>View</h5><h4> {item.title}</h4>
                                </Link>
                            </article>
                        </div>
                    ))
                    :
                    emptyArray.map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth">
                            <article>
                                <Link to="#" target="_blank">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SXSBPB/public/Images/magzine.png" className="img-fluid" alt="St. Xaviers Bathinda" /></LazyLoad>
                                    <h5>View</h5><h4> {item.title} (DD.MM.YY)</h4>
                                </Link>
                            </article>
                        </div>
                    ))}
   </div>
</div>
    </div>
   
   </div>
   </div>
   </div>
   <Footer/>
   </>
  )
}

export default JuniorCircular
